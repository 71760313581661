import React from 'react';
import './pages_css/Home.css';
import improveEnvironment from "../images/improvingourenviroment.jpg"
import weBelieve from "../images/webelieveeveryone.jpg"
import boysFishing from "../pages/pages_css/css_images/boysfishing.jpg"
import currumbin from "../pages/pages_css/css_images/currumbin.jpg"
import chairFishing from "../pages/pages_css/css_images/chairfishing.jpg"
import sunset from "../pages/pages_css/css_images/sunset.jpg"
import greenSunset from "../pages/pages_css/css_images/greensunset.jpg"
import Footer from '../components/footer';
import { Link } from "react-router-dom"

const changeClass1 = () => {
  if (document.querySelector(".home-hero")) {
    var elem = document.querySelector(".home-hero");
    elem.classList.replace("home-hero", "home-hero1")
  }
  if (document.querySelector(".home-hero2")) {
    var elem = document.querySelector(".home-hero2");
    elem.classList.replace("home-hero2", "home-hero1")
  }
  if (document.querySelector(".home-hero3")) {
    var elem = document.querySelector(".home-hero3");
    elem.classList.replace("home-hero3", "home-hero1")
  }
  if (document.querySelector(".home-hero4")) {
    var elem = document.querySelector(".home-hero4");
    elem.classList.replace("home-hero4", "home-hero1")
  }
}
const changeClass2 = () => {
  if (document.querySelector(".home-hero")) {
    var elem = document.querySelector(".home-hero");
    elem.classList.replace("home-hero", "home-hero2")
  }
  if (document.querySelector(".home-hero1")) {
    var elem = document.querySelector(".home-hero1");
    elem.classList.replace("home-hero1", "home-hero2")
  }
  if (document.querySelector(".home-hero3")) {
    var elem = document.querySelector(".home-hero3");
    elem.classList.replace("home-hero3", "home-hero2")
  }
  if (document.querySelector(".home-hero4")) {
    var elem = document.querySelector(".home-hero4");
    elem.classList.replace("home-hero4", "home-hero2")
  }
}
const changeClass3 = () => {
  if (document.querySelector(".home-hero")) {
    var elem = document.querySelector(".home-hero");
    elem.classList.replace("home-hero", "home-hero3")
  }
  if (document.querySelector(".home-hero2")) {
    var elem = document.querySelector(".home-hero2");
    elem.classList.replace("home-hero2", "home-hero3")
  }
  if (document.querySelector(".home-hero1")) {
    var elem = document.querySelector(".home-hero1");
    elem.classList.replace("home-hero1", "home-hero3")
  }
  if (document.querySelector(".home-hero4")) {
    var elem = document.querySelector(".home-hero4");
    elem.classList.replace("home-hero4", "home-hero3")
  }
}
const changeClass4 = () => {
  if (document.querySelector(".home-hero")) {
    var elem = document.querySelector(".home-hero");
    elem.classList.replace("home-hero", "home-hero4")
  }
  if (document.querySelector(".home-hero2")) {
    var elem = document.querySelector(".home-hero2");
    elem.classList.replace("home-hero2", "home-hero4")
  }
  if (document.querySelector(".home-hero3")) {
    var elem = document.querySelector(".home-hero3");
    elem.classList.replace("home-hero3", "home-hero4")
  }
  if (document.querySelector(".home-hero1")) {
    var elem = document.querySelector(".home-hero1");
    elem.classList.replace("home-hero1", "home-hero4")
  }
}
const changeClass5 = () => {
  if (document.querySelector(".home-hero2")) {
    var elem = document.querySelector(".home-hero2");
    elem.classList.replace("home-hero2", "home-hero")
  }
  if (document.querySelector(".home-hero3")) {
    var elem = document.querySelector(".home-hero3");
    elem.classList.replace("home-hero3", "home-hero")
  }
  if (document.querySelector(".home-hero4")) {
    var elem = document.querySelector(".home-hero4");
    elem.classList.replace("home-hero4", "home-hero")
  }
  if (document.querySelector(".home-hero1")) {
    var elem = document.querySelector(".home-hero1");
    elem.classList.replace("home-hero1", "home-hero")
  }
}

export default function Home() {
  return (
    <div id='home'>

      <div className='home-hero'>
        <div className='hero-text'>
          <h1>Building a better</h1>
          <h1>environment</h1>
          <Link to="/about">
            <button>About Us</button>
          </Link>
          <Link to="/events">
            <button>Get Involved</button>
          </Link>
        </div>
      </div>

      <div className='home-gallery'>
        <button onClick={() => changeClass1()}><img src={boysFishing}></img></button>
        <button onClick={() => changeClass2()}><img src={currumbin}></img></button>
        <button onClick={() => changeClass3()}><img src={chairFishing}></img></button>
        <button onClick={() => changeClass4()}><img src={sunset}></img></button>
        <button onClick={() => changeClass5()}><img src={greenSunset}></img></button>
      </div>

      <div className='home-body'>
        <div className='body-left'>
          <img src={improveEnvironment} alt="alt" />
        </div>
        <div className='body-right'>
          <h3>Improving our environment, on land and in the water.</h3>
          <p>
            We are a friendly and involved not for profit organisation dedicated to the
            preservation and protection of the environment, through providing fun and
            educational fishing experiences to all.
          </p>
        </div>
      </div>

      <div className='home-body'>
        <div className='body-right-mobile'>
          <img src={weBelieve} alt="alt" />
        </div>
        <div className='body-left'>
          <h3>We believe everyone can do their part!</h3>
          <p>Our events, programs and other exciting opportunities are created to accommodate
            all members of our community, regardless of ability, gender, socio-economic
            status or background.
          </p>
        </div>
        <div className='body-right'>
          <img src={weBelieve} alt="alt" />
        </div>
      </div>

      <div className='home-testimonial'>
        <h2>If you want to learn more about our goals, or want to get involved in helping us achieve them, check out the links below!</h2>

        <div className='testimonial-buttons'>
          <Link to="/learn-more">
            <button>Learn More</button>
          </Link>
          <Link to="/events#volunteer">
            <button>Volunteer</button>
          </Link>
          <Link to="https://donate.stripe.com/8wMcNKfheaqCffOdQS">
            <button>Donate</button>
          </Link>
        </div>
      </div>


    </div >
  );
}
