import React from 'react';
import './pages_css/Legal.css';

export default function TermsAndConditions() {
    return (
        <div id='legal'>
            <h1>Website Terms And Conditions</h1>
            <p>
                Welcome to <strong>Enviro Fish Inc. (ABN
                    85533996049)</strong> and <strong>www.envirofish.org.au,</strong> our website.
            </p>
            <p>
                We hope you
                enjoy browsing around. By visiting our website and using the services our
                website provides, you are agreeing to these terms and conditions and any
                other policies we publish or link to on our website; these form an
                “Agreement”. If you don’t agree, you must surf elsewhere; we may
                terminate your use of our website immediately if you breach our
                Agreement. We may also change, suspend or stop providing our website
                at any time.
            </p>
            <h2>YOUR OBLIGATIONS WHEN USING OUR WEBSITE</h2>
            <p>
                To provide correct information and comply with the law
                When providing us with information such as when filling out any of our
                opt-in or other forms you must give current and accurate information. You
                must also comply with the laws of QLD and Australia when you use our
                website, including but not limited to, all our intellectual property and
                cybercrime laws. Where you access our website from outside of Australia,
                you must also comply with all the relevant local laws.
            </p>
            <p>
                To only make personal and non-commercial use of our content
                You agree that the copyright in all Content on our website, is owned or
                licensed by us. You must only use the website and its Content for your
                personal and non-commercial use. Any other use is prohibited unless
                permitted by law, or with our prior written permission which you must
                seek by emailing&nbsp;
                <a href='envirofishinc@gmail.com'>envirofishinc@gmail.com</a>
                . All trademarks on our website
                belong to their respective owners.
            </p>
            <h3>To use third party software, links etc at your risk.</h3>
            <p>
                We provide links on our website to other sites. We provide the links for
                your assistance only, and we have no control over those other sites and do
                not endorse them in any way. Any use of those sites is at your sole risk and
                you must direct any concerns regarding their products or services to them.
                We also provide access to third party apps and software to enhance the
                functionality of our website. We have no control over those third party
                apps and software, and do not make any warranties in relation to them.
                You use them at your own risk and you must read and agree to their terms
                and conditions which govern your use of them.
            </p>
            <h2>WE MAKE NO WARRANTIES OR GUARANTEES</h2>
            <p>
                We cannot represent, warrant or guarantee that:
            </p>
            <p>
                our website will always be available, that your use will not be interrupted,
                or that our website is free from viruses or secure; or
            </p>
            <p>
                our content is accurate, complete and current.
            </p>
            <p>
                For example, sometimes we will be making changes to our website, or you
                may come across an old landing page, historical information, incomplete
                pages or inaccuracies. Whilst we cannot guarantee these matters will be
                corrected, please email us at&nbsp;
                <a href='envirofishinc@gmail.com'>envirofishinc@gmail.com</a>
                &nbsp;if you find any
                issues.
            </p>
            <h2>LIMITATION OF LIABILITY AND INDEMNITY</h2>
            <p>
                We exclude, to the maximum extent permitted by law, any liability in
                connection with your use of our website and its content. We are not
                responsible for any Loss or damage suffered in connection with your use of
                our website, its content, any interruptions, changes, suspension or
                termination of our website or any events beyond our control.
            </p>
            <p>
                You indemnify us for any Claim arising out of or in connection with any
                third party links, websites, apps, software, products and services; your
                breach of this Agreement, your breach of the law including your local laws,
                or your breach of the rights of a third party.
            </p>
            <h2>OTHER</h2>
            <p>
                This Agreement is governed by the laws in QLD, Australia and the parties
                submit to the jurisdiction of the courts of QLD, Australia. If any part of
                these terms and conditions is not enforceable, it shall be severed from this
                Agreement and the remaining provisions will be in full force and effect.
                The obligations and liabilities in this Agreement survive termination. The
                failure to exercise our rights, or enforce a provision under this Agreement
                does not waive the future operation of that right or ability to enforce the
                provision.
            </p>
            <h2>DEFINITIONS</h2>
            <p>
                Claim means any claim, under statute, tort, contract or negligence, any
                demand, award or costs.
            </p>
            <p>
                Content in our website could be any trademarks, brand names, logos, all
                copy, images, art, graphics, music, audio, videos, designs or any other
                content.
            </p>
            <p>
                Loss or damage means any loss or damage including, but not limited to
                any loss of salary, loss of revenue, loss of goodwill, downtime costs, loss of
                profit, loss or damage to reputation, loss of data, personal injury, property
                damage or legal costs.
            </p>
            <p>
                We, us, or our means <strong>Enviro Fish Inc. (ABN 85533996049)</strong> and includes
                any of our employees, agents, partners and contractors.
            </p>
        </div>
    );
}