import React, { useState } from "react";
import './components_css/contact.css'
import axios from 'axios';

const ContactForm = () => {
    const [status, setStatus] = useState("Submit");
    const [selectedOption, setSelectedOption] = useState("individual"); // Default selection

    const handleOptionChange = (e) => {
        setSelectedOption(e.target.value);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setStatus("Sending...");
        const { firstName, lastName, email, message } = e.target.elements;
        const emailValue = email.value;
        const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;

        if (!emailPattern.test(emailValue)) {
            setStatus("Submit");
            window.alert("Please enter a valid email address.");
            return;
        }

        let details = {
            firstName: firstName.value,
            lastName: lastName.value,
            email: email.value,
            message: message.value,
            selectedOption: selectedOption, // Include the selected option in the request
        };

        try {
            //await axios.post("https://api.envirofish.org.au/contact", details);
            await axios.post("http://localhost:5000/contact", details);
            setStatus("Message Sent");
            window.alert("Thanks for contacting us. We will get in touch with you shortly.");
        } catch (error) {
            console.error(error);
            setStatus("Failed to send message");
        }
    };

    return (
        <form onSubmit={handleSubmit} className="contact">
            <div className="form-row">
                <div className="form-group half-width">
                    <label htmlFor="firstName" className="white-text">First name:</label>
                    <input type="text" id="firstName" name="firstName" className="form-control white-text" required />
                </div>
                <div className="form-group half-width">
                    <label htmlFor="lastName" className="white-text">Last Name:</label>
                    <input type="text" id="lastName" name="lastName" className="form-control white-text" required />
                </div>
            </div>
            <div className="form-group">
                <label htmlFor="email" className="white-text">Email:</label>
                <input type="email" id="email" name="email" className="form-control white-text" required />
            </div>

            <div className="form-group">
                <label htmlFor="purpose" className="white-text">Type of Enquiry:</label>
                <select id="purpose" name="purpose" className="form-select white-text" value={selectedOption} onChange={handleOptionChange}>
                    <option value="">Please Select...</option>
                    <option value="individual">I am an Individual with an enquiry</option>
                    <option value="business">I am emailing on behalf of a business/organisation</option>
                    <option value="volunteer">I am interested in volunteering opportunities</option>
                </select>
            </div>
            <div className="form-group">
                <label htmlFor="message" className="white-text">Details:</label>
                <textarea id="message" name="message" className="form-control white-text" required />
            </div>
            <div className="submit-container">
                <button type="submit" className="submit-btn">{status}</button>
            </div>
        </form>
    );
};

export default ContactForm;