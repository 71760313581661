import React, { useState, useEffect } from 'react';
import '../pages_css/GetInvolved.css';
import { Link } from 'react-router-dom';
import { Axios } from "../../config";
import { DateRange } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import Volunteer from './volunteer';

export default function GetInvolved() {
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [eventType, setEventType] = useState('all'); // 'all', 'online', or 'in-person'
  const [eventPricing, setEventPricing] = useState('all'); // 'all', 'free', or 'paid'
  const [eventDateRange, setEventDateRange] = useState([
    {
      startDate: null,
      endDate: null,
      key: 'selection',
    },
  ]);
  const [isDateRangePickerOpen, setIsDateRangePickerOpen] = useState(false);

  useEffect(() => {
    const fetchEvents = async () => {
      try {
        const response = await Axios.get("events");
        const sortedEvents = response.data.events.sort((a, b) => new Date(a.startDate) - new Date(b.startDate));
        setEvents(sortedEvents);

      } catch (error) {
        console.error('Error fetching events:', error);
        setError('Failed to fetch events');
      } finally {
        setLoading(false);
      }
    };

    fetchEvents();
  }, []);

  function handleShowDetails(event) {
    setSelectedEvent(event);
  }

  function handleSearchChange(event) {
    setSearchQuery(event.target.value);
  }

  function handleEventTypeChange(event) {
    setEventType(event.target.value);
  }

  function handleEventPricingChange(event) {
    setEventPricing(event.target.value);
  }

  function handleDateRangeChange(item) {
    setEventDateRange([item.selection]);
  }
  const filteredEvents = events.filter(event =>
    (event.name.toLowerCase().includes(searchQuery.toLowerCase())) &&
    (eventType === 'all' || (eventType === 'online' && event.eventLocation.type === 'online') ||
      (eventType === 'in-person' && event.eventLocation.type !== 'online')) &&
    (eventPricing === 'all' || (eventPricing === 'free' && event.ticketTypes.every(ticket => ticket.price <= 0)) ||
      (eventPricing === 'paid' && event.ticketTypes.some(ticket => ticket.price > 0))) &&
    (eventDateRange[0].startDate === null && eventDateRange[0].endDate === null ||
      (new Date(event.startDate) >= eventDateRange[0].startDate && new Date(event.endDate) <= eventDateRange[0].endDate))
  );

  function EventTypeSelector() {
    return (
      <select value={eventType} onChange={handleEventTypeChange} className="selector">
        <option value="all">All Events</option>
        <option value="online">Online Events</option>
        <option value="in-person">In-Person Events</option>
      </select>
    );
  }

  function EventPricingSelector() {
    return (
      <select value={eventPricing} onChange={handleEventPricingChange} className="selector">
        <option value="all">All Events</option>
        <option value="free">Free Events</option>
        <option value="paid">Paid Events</option>
      </select>
    );
  }


  function EventDateRangeSelector() {
    return (
      <div className="date-selector-container">
        <button onClick={() => setIsDateRangePickerOpen(!isDateRangePickerOpen)} className="date-selector">
          {eventDateRange[0].startDate && eventDateRange[0].endDate
            ? `${eventDateRange[0].startDate.toLocaleDateString()} - ${eventDateRange[0].endDate.toLocaleDateString()}`
            : 'Select Date Range'}
        </button>
        {isDateRangePickerOpen && (
          <DateRange
            editableDateInputs={true}
            onChange={handleDateRangeChange}
            moveRangeOnFirstSelection={false}
            ranges={eventDateRange}
            className="date-range-picker"
          />
        )}
      </div>
    );
  }

  function EventItem({ event, onShowDetails }) {
    const options = {
      weekday: 'short',
      day: 'numeric',
      month: 'short',
      year: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    };
    const startDate = new Date(event.startDate).toLocaleString('en-AU', options);
    const endDate = new Date(event.endDate).toLocaleString('en-AU', { hour: 'numeric', minute: 'numeric', hour12: true });
    return (
      <div className="carousel-item" onClick={() => onShowDetails(event)}>
        <a
          href={`https://events.humanitix.com/${event.slug}`}
          target="_blank"
          rel="noopener noreferrer"
          className="card-link"
        >
          <img src={event.bannerImage.url} alt={event.name} />
          <div className="card-content">
            <h2>{event.name}</h2>
            <p>{event.eventLocation.address}</p>
            <p>{startDate} - {endDate}</p>
          </div>
        </a>
      </div>
    );
  }
  function Carousel({ events, onShowDetails }) {
    const itemsPerPage = 9;
    const totalPages = Math.ceil(filteredEvents.length / itemsPerPage);
    const [currentPage, setCurrentPage] = useState(1);

    const startIndex = (currentPage - 1) * itemsPerPage;
    const displayedEvents = filteredEvents.slice(startIndex, startIndex + itemsPerPage);

    function handlePageChange(page) {
      setCurrentPage(page);
    }

    return (
      <div className="carousel-container">
        {displayedEvents.length === 0 ? (
          <p>No events found.</p>
        ) : (
          <>
            <div className="carousel-grid">
              {displayedEvents.map((event) => (
                <EventItem key={event.id} event={event} onShowDetails={onShowDetails} />
              ))}
            </div>
            <PageNumbers totalPages={totalPages} currentPage={currentPage} onPageChange={handlePageChange} />
          </>
        )}
      </div>
    );
  }

  function PageNumbers({ totalPages, currentPage, onPageChange }) {
    const pages = Array.from({ length: totalPages }, (_, i) => i + 1);

    return (
      <div className="page-numbers">
        {pages.map((page) => (
          <button
            key={page}
            className={currentPage === page ? 'active' : ''}
            onClick={() => onPageChange(page)}
          >
            {page}
          </button>
        ))}
      </div>
    );
  }

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error}</p>;

  return (
    <div className="get-involved-container">
      <div className="get-involved-banner">
        <div className="involved-banner-text">
          <h1>Get Involved</h1>
        </div>
      </div>
      <div className="content-section">
        <h1 className="floating-text">
          We always have many events and opportunities available to those who wish to lend a hand in improving the
          environment, regardless of ability, socio-economic status, or background.
        </h1>

        <div className="box">
          <a className="box-item" href="https://donate.stripe.com/8wMcNKfheaqCffOdQS">Donate</a>
          <a className="box-item" href="#events">Browse Events</a>
          <a className="box-item" href="#volunteer-desktop">Volunteer</a>
          <Link className="box-item" to={'/learn-more'}>Learn More</Link>
          <Link className="box-item" to={'/contact'}>Contact Us</Link>
        </div>
      </div>
      
     

      <h1 className="browse" id="events">Browse Our Upcoming Events</h1>
      <div className="margin">‎</div>
      <div className='filter-options'>
        <div className="search-bar">
          <input
            type="text"
            placeholder="Search events..."
            value={searchQuery}
            onChange={handleSearchChange}
            className="search-input"
          />
        </div>
        <div className='selector-options'>
          <EventPricingSelector />
          <EventTypeSelector />
          <EventDateRangeSelector />
        </div>
      </div>

      <Carousel events={events} onShowDetails={handleShowDetails} />
      <div className="margin2">‎</div>


     
    </div >
  );
}
