import React from 'react';
import "../components/components_css/footer.css"
import { Link } from 'react-router-dom';
import footerLogo from "../images/footerlogo.jpg"
import facebookIcon from "../images/facebook_icon.jpg"
import instagramIcon from "../images/instagram_icon.jpg"
export default function Footer() {
  return (
    <footer>
      {/* Top Row */}
      <div className="margin"></div>
      <div className="top-row">
        <div className="column">
          <Link to="/">
            <img src={footerLogo} alt="footerlogo" />
          </Link>
        </div>

        <div className="column">
          <h3>About Us</h3>
          <ul className="link-list">
            <li><Link to="/about">Who Are We?</Link></li>
            <li><Link to="/events">Our Projects</Link></li>
            <li><Link to="/learn-more">Learn More</Link></li>
          </ul>
        </div>

        <div className="column">
          <h3>Get Involved</h3>
          <ul className="link-list">
            <li><Link to="/events">Events</Link></li>
            <li><Link to="/events#volunteer">Volunteer</Link></li>
            <li><Link to="https://donate.stripe.com/8wMcNKfheaqCffOdQS">Donate</Link></li>
          </ul>
        </div>

        <div className="column">
          <h3>Legal Info</h3>
          <ul className="link-list">
            <li><Link to="/privacy">Privacy Policy</Link></li>
            <li><Link to="/terms">Terms & Conditions</Link></li>
            <li><Link to="/event-terms">Event Terms</Link></li>
          </ul>
        </div>

        <div className='remove'>
          <div className="column">
            <h3>Contact Us</h3>
            <ul className='link-list'>
              <li><a href="mailto:envirofishinc@gmail.com">
                <p>envirofishinc@gmail.com</p>
              </a></li>
              <li><a href='tel:0403713820'>
                <p> 0403 713 820</p>
              </a></li>
            </ul>
          </div>
        </div>
        <div className="bottom-row">
          <div className="follow-us">
            <a href='https://www.facebook.com/envirofish'>
              <img src={facebookIcon} alt="Icon 1" />
            </a>
            <a href='https://www.instagram.com/envirofish/'>
              <img src={instagramIcon} alt="Icon 2" />
            </a>
          </div>
          <div className="copyright">
            © Enviro Fish Inc, 2023
          </div>
        </div>
      </div>




    </footer>
  );
}
