import React from 'react';

export default function NotFound () {

    return (
        <div>
            <h1>404 - Not Found</h1>
            <p>The page you're looking for cannot be found.</p>
        </div>
    );
}