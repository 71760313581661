import React from 'react';
import ContactForm from "../components/contact_form";
import '../pages/pages_css/ContactUs.css'
import FACEBOOK_ICON from "../images/facebook_icon.jpg"
import INSTAGRAM_ICON from "../images/instagram_icon.jpg"
import Footer from '../components/footer';

const ContactUs = () => {
    return (
        <div>
            <div className="contact-us-container">
                <div className='contact'>
                    <h1>Contact Us</h1>
                    <p>Get in touch with our team to learn about our organisation and how you can help improve the environment. For volunteering or business opportunities, please use our email enquiry form below.</p>

                    <div className="contact-info">
                        <div>
                            <h3>Phone:</h3>
                            <a href="tel:0403713820">
                                <p>0403 713 820</p>
                            </a>
                        </div>
                        <div>
                            <h3>Email:</h3>
                            <a href="mailto: envirofishinc@gmail.com">
                                <p>envirofishinc@gmail.com</p>
                            </a>
                        </div>
                        <div className="social-media">
                            <h3>Social Media:</h3>
                            <a href="https://facebook.com" target="_blank" rel="noopener noreferrer">
                                <img src={FACEBOOK_ICON} alt="Facebook" />
                            </a>
                            <a href="https://instagram.com" target="_blank" rel="noopener noreferrer">
                                <img src={INSTAGRAM_ICON} alt="Instagram" />
                            </a>
                        </div>
                    </div>
                    <div >

                    </div>
                </div>
                <div className='contactbackground'>

                    <ContactForm /> {ContactForm}
                </div>
            </div>

        </div>
    );
};

export default ContactUs;