import React from 'react';
import '../pages/pages_css/AboutUs.css';
import { Link } from 'react-router-dom'; // Ensure react-router-dom is installed
import Goals from '../images/Goals.jpg'
import Community from '../images/community.jpg'
import Fish from "../images/fish.jpg"
import Pier from "../images/Pier.jpg"



export default function AboutUs() {
  return (
    <div>

      <div className='about-us'>
        <div className="about-us-banner">
          <div className="about-banner-text">
            <h1>About Us</h1>
          </div>
        </div>
        <div className="heading-section">
          <h1 className="heading">Who Are We?</h1>
          <p className="subheading">Enviro Fish Inc. is a not for profit organisation dedicated to helping communities create a better environment, both on land and in the water.</p>
        </div>
        <div className="content-section-e">
          <div className="text-section">
            <p>Our mission is to create inclusive and accessible fishing experiences that educate and encourage others in the community to help create a better environment for all.</p>
            <p>We provide access to fishing programs regardless of ability, gender, socio-economic status or background, as well as work with organisers of local fishing competitions and programs to ensure accessibility and inclusivity.</p>
            <p>We utilise technology to better support people and our environment, as well as to provide an information outlet with various resources on doing so.</p>
            <p>Overall, we endeavor to do and teach others to leave the environment better than we found it.</p>
          </div>
          <div className="box-e">
            <Link className="box-item-e" to={"https://donate.stripe.com/8wMcNKfheaqCffOdQS"}>Donate</Link>
            <Link className="box-item-e" to={"/events#events"}>Browse Events</Link>
            <Link className="box-item-e" to={"/events#volunteer-desktop"}>Volunteer</Link>
            <Link className="box-item-e" to={"/learn-more"}>Learn More</Link>
            <Link className="box-item-e" to={"/contact"}>Contact Us</Link>
          </div>
        </div>
        <div className="heading-section">
          <h1 className="heading2">What Are Our Goals?</h1>
          <p className="subheading">Our aims of improving the environment are in line with the following United Nations Sustainable Development Goals:</p>
        </div>
        <div className="full-width-image">
          <img src={Goals} />
        </div>
        <div className='heading-section'>
          <h4>For more information: <a href='https://sdgs.un.org/goals'>https://sdgs.un.org/goals'</a></h4>
        </div>
        <div className="heading-section">
          <h1 className="heading3">What Are We Doing?</h1>
          <p className="subheading">We have many projects that we are working towards that will help us achieve our goals, as well as events and programs available to those who wish to do the same.</p>
        </div>
        <div className="image-section-e">
          <div className="image-container-e">
            <img src={Community} alt="First Event" />
            <h2>Community Fishing Days</h2>
            <p>We host plenty of community fishing days facilitated by our team of professionals, with opportunities for people of all abilities to learn how to make their fishing trips more successful.</p>
          </div>
          <div className="image-container-e">
            <img src={Fish} alt="Tilapia Skins for Burns" />
            <h2>Tilapia Skins for Burns Patients</h2>
            <p>Enviro Fish supports the use of Queensland’s invasive fish species to aid those in need -- by providing Tilapia Skins to doctors so that they can be used to support patients with burn wounds.</p>
          </div>
          <div className="image-container-e">
            <img src={Pier} alt="Accessible Jetties" />
            <h2>Making Jetties More Accessible</h2>
            <p>We at Enviro Fish are passionate about allowing everyone the opportunity to get engaged in fishing, which is why we are dedicated to installing wheelchair-accessible ramps to jetties all over Queensland.</p>
          </div>
        </div>
      </div>
    </div>
  );
}
