import React from 'react'
import Footer from '../components/footer'
import GetInvolved from './pages_components/getInvolved'
import Volunteer from './pages_components/volunteer'


export default function Events() {
  return (
    <div>
      <div>
      <GetInvolved />
      </div>
      <div>
      <div id='volunteer-desktop'>
        <Volunteer />
      </div>
      <div id='volunteer-mobile'>
        <Volunteer />
      </div>
      </div>


    </div>
    
  )
}
