import React from 'react';
import './pages_css/Legal.css';

export default function EventTerms() {
    return (
        <div id='legal'>
            <h1>Terms and Conditions for Events and Classes</h1>
            <p>
                These Terms for Classes and Events apply to all participants or all potential
                participants of <strong>Enviro Fish Inc. [ABN 85533996049]</strong> (“we, us, our”). These
                Terms for Classes and Events together with any other terms and
                conditions and policies we publish or link to on our website and services
                form an Agreement with us (“Agreement”). “You” could be any participant
                or the parent/ guardian of the participant, or organisation agreeing to
                these terms on their behalf.
            </p>
            <p>
                By clicking that you understand and agree to these Terms for Classes and
                Events, you are agreeing to be legally bound by these terms and
                conditions; if you do not agree, you cannot purchase any of our fishing
                classes, or any other activity or event that we run. We may change these
                Terms for Classes and Events at any time, and by continuing to use or
                access our website and services, you are accepting those changes.
                All participants must be 5 years of age or older to take part in our Fishing
                Classes and Events. All participants or their parent or guardians must sign
                the Release and agree to these Terms for Classes and Events.
            </p>
            <h2>PRICES AND DESCRIPTIONS ON OUR WEBSITE</h2>
            <p>
                All our prices are in Australian dollars. We may change prices for Classes
                without notice to you. We may discount or offer free entry to Classes from
                time to time. We may also discontinue any unbooked Classes at any time
                or change our range of Classes at any time without notice to you. We are
                not liable for any price changes, other changes, or any discontinued
                Classes.
            </p>
            <p>
                The descriptions of Classes and may also change without notice to you.
                There may on occasion be errors or omissions in class descriptions or
                prices, times, availability and promotions. We may, but are not obliged to,
                correct any errors or omissions, or change and update information or
                cancel Classes and Events if information is inaccurate at any time, without
                prior notice to you.
            </p>
            <h2>YOUR OBLIGATIONS AND CONDUCT RULES</h2>
            <p>
                By registering for a Class or Event you agree to provide us with various
                information. You represent and warrant that:
            </p>
            <p>
                all information you provide is true, correct and up- to date;
                you will respond promptly to any of our requests for further information.
            </p>
            <p>
                You must contact us via our website, phone call or email to book any of our
                Classes.
            </p>
            <p>
                In the event that we find that any information is not true, correct or
                current, we may at our sole discretion ban you from future Classes and
                Events.
            </p>
            <h2>PAYMENT TERMS</h2>
            <p>
                You agree that you will pay us the Fee for the Class or Event in advance via
                our website. Your place in a Class cannot be reserved or confirmed until
                payment has been received.
            </p>
            <p>
                You authorise us to deduct any outstanding unpaid Fees from your credit
                card provided. – We don’t keep card details.
            </p>
            <h2>REFUNDS, TRANSFERS</h2>
            <p>
                Where you miss a group class for any reason, we cannot provide a refund.
                Any transfers to other classes or schools are made at our sole discretion
                and are subject to numbers and availability.
            </p>
            <p>
                To the extent permitted by law, any refunds are at our absolute discretion.
                We do not provide refunds for your change of mind, or where you failed to
                provide us with adequate information, or can no longer attend for any
                reason, including if you are sick. On occasion where we need to cancel the
                Class because of weather and cannot arrange a future date we may give
                you a refund. Where the Class cannot be rescheduled due to your time
                restraints eg a birthday party, we may, but are not obliged to provide a
                refund.
            </p>
            <h2>FISHING CLASS CHANGES</h2>
            <p>
                Despite our reasonable endeavours, on occasion we may need to change
                locations, times or dates at short notice or even cancel a Class or Event. We
                will notify you as soon as possible of any Class changes. In these
                circumstances we may offer a transfer to another Class if the new location,
                time or date is inconvenient to you. We do not provide refunds except as
                required under the Australian Consumer Law.
            </p>
            <h2>WEATHER POLICY</h2>
            <p>
                Our Classes go ahead in light rain. In the event that weather appears to be
                inclement, we will reschedule the Class. During the Class or Event if the
                weather is deemed to become unsafe, our staff will also cancel the
                remainder of the class and reschedule where there is more than half the
                class time left.
            </p>
            <h2>CONSUMER GUARANTEES AND REFUNDS</h2>
            <p>
                You are entitled to various consumer guarantees, rights, and remedies
                under the Australian Consumer Law including, but not limited to, the right
                to the Fishing Classes being delivered with due care and skill.
                If you consider that we have failed to meet a consumer guarantee, please
                notify us at&nbsp;
                <a href='envirofishinc@gmail.com'>envirofishinc@gmail.com</a>
            </p>
            <h2>PHOTO PERMISSION</h2>
            <p>
                By joining in activities facilitated by Enviro Fish Inc. I hereby grant
                permission to Enviro Fish Inc. and their representatives to photograph &
                use my image/s in their markering. I release the aforementioned involved
                from any liability connected with the taking, recording of said photograph,
                video or computer images. I further more give Enviro Fish Inc permission
                to take and use photographs and/or digital images/video of me. If under 18
                years, I declare I have the consent of my parent or guardian) for use in:
                <ol>
                    <li> Media releases, media articles – including newspapers, radio, television –
                        printed publications and/or educational materials</li>
                    <li> Electronic publications and communications such as the Enviro Fish Inc.
                        Facebook site and website</li>
                    <li> I agree that my name and identity may be revealed in descriptive text or
                        commentary in connection with the image(s).</li>
                    <li> I authorise the use of these images without compensation to me. All
                        negatives, prints, digital reproductions and shall be the property of Enviro
                        Fish Inc.</li>
                </ol>
            </p>
            <h2>MARKETING</h2>
            <p>
                Where you have provided consent to your photo or video being taken, you
                permit us to use the photographs or videos for marketing and information
                purposes, or publications exhibitions and professional awards. You must
                seek our prior written consent before any publication of information about
                us.
            </p>
            <h2>TERMINATION</h2>
            <p>
                We may refuse to provide Classes to you, or anyone, for any reason, at any
                time. In particular, if you breach this Agreement we may immediately
                terminate ban your purchase of any classes. We can also stop offering our
                Classes at any time, for any reason, and without notifying you. We are not
                responsible to you for any changes, or if we suspend or stop our classes.
            </p>
            <h2>LIABILITY AND INDEMNITY</h2>
            <p>
                Your use of, or inability to use our website and services at your sole risk. To
                the maximum extent permitted by law, we are not liable for any Loss or
                damage arising from your attendance at a Class or Event including, but
                not limited to, any errors or omissions in any website content, price
                changes or discontinued services, changes to the location, dates and times
                of Classes, and any interruptions, any changes, cancellation of a class.
            </p>
            <p>
                To the extent which we are entitled to do so, our liability under the
                Australian Consumer Law will be limited, at our option to:
                the supply of equivalent services eg participation in another Class; or
                the payment of the cost of the Class or Event.
            </p>
            <p>
                In any case, our liability to you will not exceed the amount actually paid by
                you to us for the most recent class you have paid for.
            </p>
            <p>
                You agree to indemnify us, and to keep us indemnified from any Claim
                arising out of or in connection with your breach of this Agreement,
                including the Conduct Rules, where you provide incorrect personal
                information, any disclosure of your personal information.
            </p>
            <h2>IF THERE IS A DISPUTE</h2>
            <p>
                In the event of any dispute that cannot be resolved, both parties agree to
                obtain an independent professional arbitrator/dispute resolution specialist
                to make a determination on the dispute and each party agrees to pay their
                own costs.
            </p>
            <h2>OTHER</h2>
            <p>
                This Agreement is to be construed in accordance with the laws of QLD ,
                Australia, and you and we submit to the jurisdiction of the courts of QLD,
                Australia. This is the entire agreement between you and us, and
                supersedes any prior agreements, proposals and communications
                whether oral or written, between you and us. In the event of an
                inconsistency between these Terms for Classes and Events and other
                terms and conditions on our website, these Terms for Classes and Events
                prevail. No other term is to be included in this Agreement except where it
                is required to be included by law. The failure by us to exercise any right, or
                enforce any provision in these Terms does not waive the future operation
                of that right or provision. In the event that a provision in this Agreement is
                not enforceable, such provision shall be severed from this agreement to
                the extent permitted by law, and the remaining provisions will remain in
                full force and effect. All obligations and liabilities in these Agreement
                survive termination of this Agreement.
            </p>
            <h2>DEFINITIONS</h2>
            <p>
                Claim means any claim, under statute, tort, contract or negligence, any
                demand, award or costs.
            </p>
            <p>
                Classes and Events are the individual or group classes or community
                events, we agree to provide to you.
            </p>
            <p>
                Loss or damage means any direct, indirect, incidental, punitive, special, or
                consequential loss or damages of any kind, including but not limited to,
                any loss of profits, revenue, savings, loss of data, loss of enjoyment, virus to
                systems, personal injury, death, negligence, trespass, property damage
                and legal costs.
            </p>
            <p>
                We, us, or our means <strong>Enviro Fish Inc. (ABN 85533996049)</strong> and includes
                any of our employees, agents, partners, contractors
            </p>
            <p>
                Website and services means <strong>www.envirofish.org.au</strong>, and everything
                available on this website including, but not limited to, all Classes and
                Events and other products and services.
            </p>
        </div>
    );
}