import React, { useState } from 'react';
import navbarlogo from '../../images/navbarlogo2.jpg'
import "../components_css/mobile_component_css/navbarMobile.css";

export default function NavbarMobile() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  return (
    <nav className="navbarMobile">
      <a href='/'>
        <img
          src={navbarlogo}
          className="navbar-logo"
          alt="Company Logo"
          style={{ width: '100px', height: 'auto' }}
        />
      </a>
      <button className="menuButton" onClick={() => setIsMenuOpen(!isMenuOpen)}>
        <div className="hamburgerIcon">
          <div></div>
          <div></div>
          <div></div>
        </div>
        <span className="menuText">Menu</span>
      </button>
      {isMenuOpen && (
        <div className="dropdownMenu">
          <a href="/">Home</a>
          <a href="/about">About Us</a>
          <a href="/events">Get Involved</a>
          <a href="/learn-more">Learn More</a>
          <a href="https://donate.stripe.com/8wMcNKfheaqCffOdQS">Donate</a>
          <a href="/contact">Contact Us</a>
        </div>
      )}
    </nav>
  );
}
