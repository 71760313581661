import React from 'react';
import { Link } from 'react-router-dom';
import footerLogo from "../../images/footerlogo.jpg";
import facebookIcon from "../../images/facebook_icon.jpg";
import instagramIcon from "../../images/instagram_icon.jpg";
import "../components_css/mobile_component_css/footerMobile.css"

export default function FooterMobile() {
  return (
    <div className='footer-container'>
      <div className="footer-links">
        <Link className='footer-link' to="/about">About Us</Link>
        <Link className='footer-link' to="/events">Get Involved</Link>
        <Link className='footer-link' to="/contact">Contact Us</Link>
      </div>
      <div className="footer-links">
        <Link className='footer-link' to="/privacy">Privacy Policy</Link>
        <Link className='footer-link' to="/terms">Terms & Conditions</Link>
        <Link className='footer-link' to="/event-terms">Event Terms</Link>
      </div>
      <div className="footer-logo">
        <Link to="/">
          <img src={footerLogo} alt="EnviroFish" className="footer-image" />
        </Link>
      </div>

      <hr className="footer-divider" />

      <div className="bottom-row">
        <div className="social-media">
          <a href='https://www.facebook.com/envirofish'>
            <img src={facebookIcon} alt="Facebook" className="social-icon" />
          </a>
          <a href='https://www.instagram.com/envirofish/'>
            <img src={instagramIcon} alt="Instagram" className="social-icon" />
          </a>
        </div>
        <div className="copyright-statement">
          © Enviro Fish Inc, 2023
        </div>
      </div>
    </div>
  )
}
