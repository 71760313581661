import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import ContactForm from './pages/contactUs';
import Home from './pages/home';
import AboutUs from './pages/aboutUs';
import Navbar from './components/navbar';
import Events from './pages/events';
import LearnMore from './pages/learnMore';
import Privacy from './pages/privacy';
import TermsAndConditions from './pages/termsAndConditions';
import EventTerms from './pages/eventTerms';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import NavbarMobile from './components/mobile_components/navbarMobile';
import NotFound from './pages/notFound';

import FooterMobile from './components/mobile_components/footerMobile';
import Footer from './components/footer';

const App = () => {

  function ScrollToTop() {
    const { pathname, hash } = useLocation();

    useEffect(() => {
      if (hash) {
        const element = document.getElementById(hash.slice(1));
        if (element) {
          element.scrollIntoView();
        }
      } else {
        window.scrollTo(0, 0);
      }
    }, [pathname, hash]);

    return null;
  }
  return (
    <Router>
      <ScrollToTop />
      <div className='hide-on-mobile'>
        <Navbar />
      </div>
      <div className='hide-on-desktop'>
        <NavbarMobile />
      </div>
      <Routes>
        <Route path="/" element={< Home />} />
        <Route path="/about" element={< AboutUs />} />
        <Route path="/events" element={< Events />} />
        <Route path="/contact" element={< ContactForm />} />
        <Route path="/learn-more" element={< LearnMore />} />
        <Route path="/privacy" element={< Privacy />} />
        <Route path="/terms" element={< TermsAndConditions />} />
        <Route path="/event-terms" element={< EventTerms />} />
        <Route path="*" element={<NotFound />} />

      </Routes>
      <div className='hide-on-mobile'>
        <Footer />
      </div>
      <div className='hide-on-desktop'>
        <FooterMobile />
      </div>
    </Router>
  );
}

export default App;