import React from 'react';
import './pages_css/Legal.css';

export default function Privacy() {
    return (
        <div id='legal'>
            <h1>Privacy Policy</h1>
            <p>
                This policy outlines the way we at <strong>Enviro Fish Inc. (ABN
                    85533996049)</strong> collect, hold, use and disclose personal information. We may
                collect personal information of adult and children clients.
            </p>
            <h2>WHAT PERSONAL INFORMATION WE COLLECT & HOW AND WHY WE COLLECT IT?</h2>
            <h3>What personal information do we collect?</h3>
            <p>
                The personal information we collect is generally limited to:
                <ul>
                    <li>your name and contact details;</li>
                    <li>billing information; and</li>
                    <li>any communications we have.</li>
                </ul>
            </p>
            <p>
                We may also collect:
                <ul>
                    <li>sensitive information from you with your consent, such as where we
                        ask for information about your health; and</li>
                    <li>information about how you use our website, via third parties.</li>
                </ul>
            </p>
            <h3>How do we collect your personal information?</h3>
            <p>
                Personal information is collected primarily through you directly via phone,
                email, comments or feedback or via social media. However, we may also
                collect personal information and sensitive information from a parent/
                guardian, or other relevant person such as a teacher or council
                representative.
            </p>
            <p>
                At times personal information may also be gathered through third parties,
                such as Google Analytics or Facebook Pixel. These third parties may use
                cookies, web beacons and similar technology to collect or receive
                information about you from our website and elsewhere on the internet.
            </p>
            <h3>Why do we collect your personal information?</h3>
            <p>
                We need your personal information to:
                <ul>
                    <li>communicate with you in relation to your enquiry;</li>
                    <li>conduct our business; and to</li>
                    <li>comply with our legal obligations, such as record keeping.</li>
                </ul>
                Your sensitive information will only be used for the purpose for which you
                gave it to us unless authorised by law.
            </p>
            <p>
                We also collect personal information to analyse and enhance our business
                operations and improve your experience with our business. This is used as
                statistical information to analyse traffic to our website, and to customise
                content and advertising we provide.
            </p>
            <p>
                You can opt out of the collection and use of this information by changing
                your privacy settings or opting out. To opt out you can go here:&nbsp;
                <a href='https://www.facebook.com/ads/website_custom_audiences/'>https://www.facebook.com/ads/website_custom_audiences/</a>
                &nbsp;or here:&nbsp;
                <a href='https://tools.google.com/dlpage/gaoptout'>https://tools.google.com/dlpage/gaoptout</a>
                .
            </p>
            <h2>WHEN DO WE DISCLOSE PERSONAL INFORMATION & HOW YOU CAN ACCESS IT?</h2>
            <h3>When do we disclose your personal information?</h3>
            <p>
                We will take reasonable precautions to protect your personal information,
                including against loss, unauthorised access, disclosure, misuse or
                modification. It is kept securely and accessible only to authorised
                personnel. Information is kept in accordance with our legal record keeping
                obligations and then destroyed appropriately.
            </p>
            <p>
                We generally will not disclose your personal information unless:
                <ul>
                    <li>you consent;</li>
                    <li>it is required or authorised by law*; or</li>
                    <li>it is reasonably necessary for one of the purposes for which we
                        collect it.</li>
                </ul>
                *This can include where we are of the reasonable belief that there is a
                serious risk to life, health or safety of you or another person. For example, if
                there is evidence of clear danger of harm to self and/or others, we may be
                legally required to report this information to the authorities responsible for
                ensuring safety. This includes if there is a strong suspicion of physical or
                sexual abuse or neglect of any person under 18 years of age. A court order,
                issued by a judge, may also require the us to release information contained
                in records and/or require a therapist to testify in a court hearing.
            </p>
            <p>
                We will only disclose your sensitive information for the purpose for which
                you gave it to us or for directly related purposes that you would reasonably
                expect or if you otherwise agree.
            </p>
            <p>
                We may also disclose your personal information where it is necessary to
                obtain third party services, such as analytics, data storage, payment service
                providers or marketing and advertising services. To protect your personal
                information we endeavour to ensure that our third party service providers
                also comply with the Australian Privacy Principles, but some third parties
                we use such as Google, Facebook may collect and hold personal
                information overseas. You can opt out of the collection and use of this
                information by changing your privacy settings or opting out.
            </p>
            <h3>How can you access or delete your information?</h3>
            <p>
                If you want access to your information to correct or have it deleted please
                email us at&nbsp;
                <a href='mailto: envirofishinc@gmail.com'>envirofishinc@gmail.com</a>
                . Except where we are permitted or
                required by law to withhold it, we will help you. Please allow up to 30 days
                for your response. If you consider that we have breached any privacy laws
                please also email us at&nbsp;
                <a href='mailto: envirofishinc@gmail.com'>envirofishinc@gmail.com</a>
                . You can make a
                complaint with the Office of the Australian Information Commissioner
                phone on&nbsp;
                <a href='tel:1300 363 992'>1300 363 992</a>
                , online at&nbsp;
                <a href='http://www.oaic.gov.au/privacy/making-a-privacy-complaint'>http://www.oaic.gov.au/privacy/making-a-privacy-complaint</a>
                &nbsp;or post to: <strong>Office of the Australian Information
                    Commissioner, GPO Box 5218, Sydney, NSW 2001.</strong>
            </p>
        </div>
    );
}